//-----------------------------------------------General Style------------------------------------
#root {
  height: 100vh;

  main {
    height: 100%;
  }
}

//Because semantic ui does not let us customise font-weight per header type
h1.ui.header {
  font-weight: 300;
}

//Give the right font size to the h6 (semantic-ui does not handle h6)
h6 {
  font-size: 12px;
}

//---------------------------------------------Date Picker Style --------------------------------------
//Make date picker full width in project form
.react-datepicker-wrapper {
  width: 100%;
}

//-----------------------------------------------Designer Tool Style------------------------------------
#designer-toolbox {
  .row,
  .column {
    padding: 0.25rem;
  }

  h6 {
    margin: 0.5rem 0;
  }

  .input,
  .button,
  .dropdown,
  .designer-menu,
  .button {
    border-radius: 0.5rem;
    > .item.active {
      border-radius: 0.5rem;
      background-color: #6b50ff;
      color: white;
    }
  }

  .button {
    margin-right: 0;
  }

  .divider {
    margin: 0;
  }

  label {
    margin: 0.5rem 0;
    color: #7f7f7f;
    font-size: 12px;
  }

  input,
  .dropdown {
    background: transparent;
  }

  .button.toggle {
    &.active {
      background-color: #12165b55 !important;
    }
  }
}

//Hide scroll bar for the preview contributor pages
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

//-----------------------------------------------FilePond style------------------------------------
.filepond-wrapper {
  .filepond--drop-label {
    border: 2px dashed #6b50ff !important;
    border-radius: 4px;
    font-family: inherit;
  }

  span.filepond--label-action {
    text-decoration: none;
    strong {
      text-decoration: underline;
      font-weight: bold;
    }
  }

  //Hide upload/retry button from the filepond control to force user using the "Submit" button.
  .filepond--file {
    .filepond--action-process-item,
    .filepond--action-retry-item-processing {
      display: none;
    }
  }
}
