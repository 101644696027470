//This style sheet is made to customise the preview of the upload page with the team style.
html {
  --primaryColor: #6b50ff;
  --primaryFont: Roboto;
  --buttonTextColor: #ffffff;

  #phone-frame {
    //DO not apply font family to icon otherwise they won't display the icon image
    *:not(i) {
      font-family: var(--primaryFont);
    }
    
    button {
      text-transform: uppercase;
      color: var(--buttonTextColor);
      background-color: var(--primaryColor);
    }
  }
}
