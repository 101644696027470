#search-field-modal {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
  padding: 1rem;

  @media screen and (min-width: 769px) {
    height: 30vh;
  }
  .react-mapbox-ac-menu {
    position: absolute;
    z-index: 9999;
    background-color: #fff;
    border: 1px solid #ccc;
  }

  .react-mapbox-ac-suggestion {
    cursor: pointer;
    padding: 1rem 1.5rem;
    font-size: 18px;
  }

  .react-mapbox-ac-suggestion:hover {
    background-color: #58a;
  }
}
