[id^='font-picker'] {
  box-shadow: none !important;
  width: 100% !important;
  button {
    height: 100% !important;
    padding: 1rem 1.5em 1rem !important;
    border-radius: 4px;
    font-size: 14px !important;
  }
}
